window.esign = window.esign || {};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $html: $('html'),

    // navigation
    $nav: $('.main-nav__wrap')

  };
};

esign.init = function () {

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752, 0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  //esign.navigation();
  esign.responsiveVideos();
  esign.formAjax();
  esign.floatingLabels();

  esign.blogDetail();
  esign.productDetail();
  esign.moveElements();

  esign.history();

  objectFitImages();

  esign.modals();

  $('.scrollto').on('click', function (e) {
    var target = $(this).attr('href');

    if ($(target).length == 1) {
      e.preventDefault();
      //close modal
      $('.md-show').removeClass('md-show');
      esign.cache.$html.removeClass('noscroll');

      location.hash = '';



      $('html, body').animate({
        scrollTop: $(target).offset().top
      }, 1500);
    }

  });


  if ($('body').hasClass('home')) {
    esign.homeBlogSlideshow();
  }

  $.each($('.dotdotdot'), function (index, el) {
    $(el).dotdotdot({
      watch: 'window'
    });
  });

  $('.lang-wrap').on('click', function () {
    $(this).toggleClass('active');
  });


  $(window).on('resize', function () {
    esign.blogDetail();
  });

  var myLazyLoad = new LazyLoad();

  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS) {
    $('html').addClass('ios-device')
  }


};


esign.history = function () {

  var fadeTime = 400;


  $('.history-item-wrap').hide();

  $('.history-item-wrap').first().fadeIn('fast');

  $('.timeline__event__button').first().addClass('timeline__event__button--active');

  $('.timeline__event__button').on('click', function () {
    var eventId = $(this).data('event-id');
    var $connectedHistoryItem = $('.history-item-wrap[data-event-id=' + eventId + ']');


    if ($connectedHistoryItem.length != 0) {

      $('.history-item-wrap').fadeOut(fadeTime);

      setTimeout(function () {
        $connectedHistoryItem.fadeIn(fadeTime);
      }, fadeTime);

      $('.timeline__event__button').removeClass('timeline__event__button--active');
      $(this).addClass('timeline__event__button--active');

    }

  });


  $('.history-item__link-pager').on('click', function () {

    var eventId = $(this).data('event-id');
    //console.log('el',$(this).parent('.history-item-wrap').next('.history-item-wrap'), eventId);
    var $connectedHistoryItem = $('.history-item-wrap[data-event-id=' + eventId + ']');
    var $connectedHistoryButton = $('.timeline__event__button[data-event-id=' + eventId + ']');

    $('.timeline__event__button').removeClass('timeline__event__button--active');
    $('.history-item-wrap').fadeOut(fadeTime);

    setTimeout(function () {
      $connectedHistoryItem.fadeIn(fadeTime);
    }, fadeTime);


    $connectedHistoryButton.addClass('timeline__event__button--active');

  });
};


esign.moveElements = function () {

  /*  var movePaymentMethods = {
   element: $('.payment-methods-wrap'),
   origPos: 'payment-meth-pos1',
   origWhere: 'after',
   //let op de volgorde waarin je je breakpoints plaatst
   breakpoints: [
   {
   'maxWidth': 767,
   'positionId': 'payment-meth-pos2',
   'where': 'after'
   }
   ]
   };*/

  var moveVisualHome = {
    element: $('.accent-block__visual--home'),
    origPos: 'accent-block__visual-wrap',
    origWhere: 'inside-first',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'accent-block-wrap--home',
        'where': 'before'
      }
    ]


  };


  var moveHeaderHome = {
    element: $('.accent-block__header--home'),
    origPos: 'accent-block__text-wrap',
    origWhere: 'inside-first',
    //let op de volgorde waarin je je breakpoints plaatst
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'wrap-home-intro-block',
        'where': 'inside-first'
      }
    ]


  };


  var moveBodyProductsHome = {
    element: $('.products-collection__body--home'),
    origPos: 'grid-item-products',
    origWhere: 'inside-first',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'container-products',
        'where': 'inside-last'
      }
    ]


  };

  var moveCatalogProductsHome = {
    element: $('.catalogus-cta--home'),
    origPos: 'leftpart-product-categories',
    origWhere: 'inside-last',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'wrap-product-categories',
        'where': 'inside-last'
      }
    ]
  };


  var moveCatalogProductsProductDetail = {
    element: $('.catalogus-cta-grid--product-detail'),
    origPos: 'main-grid-product',
    origWhere: 'after',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'container-product',
        'where': 'after'
      }
    ]


  };

  var moveProductboxImg = {
    element: $('.product-box'),
    origPos: 'product-box-grid-item',
    origWhere: 'inside',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'available-in',
        'where': 'after'
      }
    ]


  };

  var moveCheeseProductCategory = {
    element: $('.js-product-category--cheese'),
    origPos: 'js-product-category--cheese-orig',
    origWhere: 'inside',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'leftpart-product-categories',
        'where': 'inside-last'
      }
    ]


  };
  //
  var moveProductGoBack = {
    element: $('.blog-pager__item--back'),
    origPos: 'product-name',
    origWhere: 'after',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'container-product',
        'where': 'inside-last'
      }
    ]
  };


  var moveProductWithPrice = {
    element: $('.product-with-prize'),
    origPos: 'orig-pos-product-with-price',
    origWhere: 'inside',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'accent-block-wrap--about',
        'where': 'inside-first'
      }
    ]
  };

  var moveAboutImg = {
    element: $('.product-with-prize'),
    origPos: 'orig-pos-product-with-price',
    origWhere: 'inside',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'accent-block-wrap--about',
        'where': 'inside-first'
      }
    ]
  };

  var moveAboutTitle = {
    element: $('.accent-block__header--about'),
    origPos: 'accent-block__text-wrap',
    origWhere: 'inside-first',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'accent-block-wrap--about',
        'where': 'inside-first'
      }
    ]
  };

  var moveContactVisual = {
    element: $('.contact-visual'),
    origPos: 'contact-visual-grid-item',
    origWhere: 'inside-last',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'container-contact',
        'where': 'before'
      }
    ]
  };

  var moveArrowsSlider = {
    element: $('.news-nav'),
    origPos: 'wrap-slider',
    origWhere: 'inside-last',
    breakpoints: [

      {
        'maxWidth': 1200,
        'positionId': 'grid-item-wrap-slider',
        'where': 'after'
      }
    ]
  };
  var moveContactTitle = {
    element: $('.contact-visual__title'),
    origPos: 'contact-visual',
    origWhere: 'inside-last',
    breakpoints: [

      {
        'maxWidth': 767,
        'positionId': 'container-contact',
        'where': 'before'
      }
    ]
  };

  $.each($('.history-wrap li'), function (index, value) {
    // console.log(index, value);
    var eventId = $(this).data('event-id');
    moveOnResize({
      element: $('.' + eventId + '__img'),
      origPos: eventId + '__grid',
      origWhere: 'inside-first',
      breakpoints: [

        {
          'maxWidth': 767,
          'positionId': eventId + '__wrap-title-year',
          'where': 'after'
        }
      ]
    });
  });


  moveOnResize(moveVisualHome);
  moveOnResize(moveHeaderHome);
  moveOnResize(moveBodyProductsHome);
  moveOnResize(moveCatalogProductsHome);
  moveOnResize(moveCatalogProductsProductDetail);
  moveOnResize(moveCheeseProductCategory);
  moveOnResize(moveProductboxImg);
  moveOnResize(moveProductGoBack);
//  moveOnResize(moveProductWithPrice);
  moveOnResize(moveAboutImg);

  moveOnResize(moveAboutTitle);
  moveOnResize(moveContactVisual);
  moveOnResize(moveArrowsSlider);


  function moveOnResize(moveElement) {
    checkBreakpoints(moveElement);

    $(window).on('resize', function () {
      checkBreakpoints(moveElement);
    });

  }

  function placeOnPositionWithId(element, id, where) {
    if (where == undefined) {
      where = 'after';
    }
    var placement = $('[data-anchor-id = ' + id + ']');


    switch (where) {

      case 'before':
        $(element).insertBefore($(placement));
        break;

      case 'after':
        $(element).insertAfter($(placement));
        break;

      case 'inside':
        $(placement).append($(element));
        break;

      case 'inside-last':
        $(placement).append($(element));
        break;
      case 'inside-first':
        $(placement).prepend($(element));
        break;

      default:
        $(element).insertAfter($(placement));
    }
  }

  function breakpointCheck(breakpoint) {

    var checks = [];

    if (breakpoint.minWidth != undefined && breakpoint.minWidth != null) {
      //checks.push($(window).width() > breakpoint.minWidth)
      checks.push(window.matchMedia('(min-width: ' + breakpoint.minWidth + 'px)').matches);
    }

    if (breakpoint.maxWidth != undefined && breakpoint.maxWidth != null) {
      //checks.push( $(window).width() < breakpoint.maxWidth);
      checks.push(window.matchMedia('(max-width: ' + breakpoint.maxWidth + 'px)').matches);
    }

    if (breakpoint.minHeight != undefined && breakpoint.minHeight != null) {
      //checks.push( $(window).height() > breakpoint.minHeight);
      checks.push(window.matchMedia('(min-height: ' + breakpoint.minHeight + 'px)').matches);

    }
    if (breakpoint.maxHeight != undefined && breakpoint.maxHeight != null) {
      //checks.push($(window).height() < breakpoint.maxHeight);
      checks.push(window.matchMedia('(max-height: ' + breakpoint.maxHeight + 'px)').matches);
    }

    //console.log(checks);
    return checks.indexOf(false) == -1;

  }


  function checkBreakpoints(moveElement) {

    var possiblePositions = [];

    $.each(moveElement.breakpoints, function (index, breakpoint) {
      if (breakpointCheck(breakpoint)) {
        possiblePositions.push(breakpoint);
        placeOnPositionWithId(moveElement.element, breakpoint.positionId, breakpoint.where);
      }

    });

    if (!possiblePositions.length > 0) {
      placeOnPositionWithId(moveElement.element, moveElement.origPos, moveElement.origWhere);
    }

  }

};

esign.homeBlogSlideshow = function () {
  var $articles = $('.news-article');
  var $previews = $('.news-article-preview');
  var nrOfPreviews = $previews.length;
  var containerWidth = $('.news-article-previews').width();
  var currentIndex = 0;

  var nrOfPreviewsVisible = 3;

  var nextPreviewMap = {};

  function calculateStartPositions() {
    $previews.each(function (index) {
      $(this).css({left: (index) * containerWidth / nrOfPreviewsVisible, width: containerWidth / nrOfPreviewsVisible});
    });
  }

  function updatePreviews(currentIndex) {
    $previews.each(function (index) {
      // because previews is an array the order is guaranteed, so we can use the index
      if (index >= currentIndex && index < currentIndex + nrOfPreviewsVisible) {
        $(this).addClass('js-news-article-preview--show');
      } else {
        $(this).removeClass('js-news-article-preview--show');
      }

      var $article = $($articles.get(index));
      if (index < currentIndex) {
        $article.removeClass('js-news-article--viewed-right');
        $article.addClass('js-news-article--viewed-left');
        $(this).removeClass('js-news-article-preview--active');
      } else if (index > currentIndex) {
        $article.addClass('js-news-article--viewed-right');
        $article.removeClass('js-news-article--viewed-left');
        $(this).removeClass('js-news-article-preview--active');
      } else { // index == currentIndex
        $article.removeClass('js-news-article--viewed-right');
        $article.removeClass('js-news-article--viewed-left');
        $(this).addClass('js-news-article-preview--active');
      }
    });
    // update positions
    _updatePreviewPositions(currentIndex);
  }

  function _updatePreviewPositions(currentIndex) {
    if (currentIndex === 0) {
      calculateStartPositions();
      return;
    }

    $currentPreview = $($previews[currentIndex]);
    var diff = parseInt($currentPreview.css('left'));
    $currentPreview.css({'left': 0});

    var nextVisiblePreviews = [];
    for (var i = 0; i < nrOfPreviewsVisible - 1; i++) {
      var index = (currentIndex + i) % nrOfPreviews;
      nextVisiblePreviews.push(nextPreviewMap[index]);
    }

    for (var i = 0; i < nextVisiblePreviews.length; i++) {
      var $preview = nextVisiblePreviews[i];
      $preview.css({left: (i + 1) * containerWidth / nrOfPreviewsVisible + 'px'});
      $preview.addClass('js-news-article-preview--show');
    }

    $previews.each(function (index) {
      if (!$(this).hasClass('js-news-article-preview--show')) {
        $(this).css({left: parseInt($(this).css('left')) - diff + 'px'});
      }
    })
  }

  function onClickPreview(index) {
    index = index % nrOfPreviews;
    if (index < 0) {
      index = nrOfPreviews - 1;
    }
    // remove old article
    var $oldArticle = $($articles.get(currentIndex));
    var isMoveLeft = index > currentIndex;
    if (isMoveLeft) {
      $oldArticle.addClass('js-news-article--viewed-left');
    } else {
      $oldArticle.addClass('js-news-article--viewed-right');
    }
    $oldArticle.removeClass('js-news-article--show');


    // show clicked article
    currentIndex = index;
    var $newArticle = $($articles.get(currentIndex));
    $newArticle.addClass('js-news-article--show');

    //article updated, so also update the previews
    updatePreviews(currentIndex)
  }


  $('.news-nav--prev').click(function () {
    onClickPreview(currentIndex - 1);
  });

  $('.news-nav--next').click(function () {
    onClickPreview(currentIndex + 1);
  });

  function onPageResize() {
    containerWidth = $('.news-article-previews').width();

    $previews.each(function (index) {
      var mappingIndex = index - 1 >= 0 ? index - 1 : nrOfPreviews - 1;
      nextPreviewMap[mappingIndex] = $(this);

      $(this).find('.news-article-preview__trigger').click(function () {
        onClickPreview(index);
      });
    });
    calculateStartPositions();
    updatePreviews(currentIndex);
  }

  $($articles[0]).addClass('js-news-article--show');
  $(window).resize(onPageResize);
  onPageResize(); // on load


};

esign.blogDetail = function () {
  if ($(window).width() > 767) {
    var blogInfo = $('.blog-detail__info').outerHeight(true);
    $('.blog-detail__image').css('max-height', $('.blog-detail__info').outerHeight(true));
  } else {

  }

};

esign.productDetail = function () {

  $('.js-product__change-boximg').first().addClass('active');

  $.each($('.js-product__change-boximg'), function (index, el) {
    $(el).on('click', function (e) {
      e.preventDefault();
      $('.js-product__change-boximg').removeClass('active');
      var imgsrc = $(this).data('changebox-img');
      var bgsrc = $(this).data('changebox-img');
      $(this).addClass('active');

      $('.product-box img').attr('src', imgsrc);
      $('.product-box__img-visual').css('background-image',"url('"+ bgsrc + "')");
    });
  });

};

esign.navigation = function () {
  $('.main-nav__trigger').on('click touchend', function (e) {
    e.preventDefault();
    $(this).next('.main-nav__wrap').slideToggle('fast');
  });

  Response.crossover('width', function () {
    if (Response.band(752)) {
      esign.cache.$nav.css('display', 'block');
      $('.main-nav__wrap').slideDown(0);
    } else {
      esign.cache.$nav.css('display', 'none');
      $('.main-nav__wrap').slideUp(0);
    }
  });
};
esign.floatingLabels = function () {

  if (document.createElement("input").placeholder == undefined) {
    $('.floating-label--container input').addClass('active');
    $('.floating-label--container label').addClass('active');
  } else {
    $('.floating-label--container').each(function (index, element) {
      $(element).find('input').on('select keydown', function () {
        $(this).parent().find('label').addClass('active');
        $(this).addClass('active');
      });

      $(element).find('input').on('keyup blur ', function () {
        if ($(this).parent().find('input').val().length == 0) {
          $(this).parent().find('label').removeClass('active');
          $(this).removeClass('active');
        }
      });
    });
  }

};


esign.modals = function () {

  // btns
  $('.modal-button, .main-modal-trigger').click(function (e) {
    e.preventDefault();
    var $modal;

    if ($(this).hasClass('main-modal-trigger')) {
      $modal = $('#main-modal');
    } else {
      $modal = $($(this).attr('href'));
    }

    /* convert to md-cover if height > window height or device is mobile */
    if ($modal.height() > $(window).height() || esign.cache.IS_MOBILE) {

      if (!$modal.hasClass('md-cover')) {

        var $close = $modal.find('.md-close');

        $modal
          .addClass('md-cover')
          .find('.md-content')
          .wrapInner('<div class="md-center"><div class="container small"></div></div>')
          .after($close);

        $close.wrap('<div class="md-topbar"></div>');

      }

    }

    if ($modal) {
      // remove current modal
      $('.md-show').removeClass('md-show');
      esign.cache.$html.removeClass('noscroll');

      // show modal
      $modal.toggleClass('md-show');
      if ($modal.hasClass('md-cover')) {
        esign.cache.$html.addClass('noscroll');
      }

      location.hash = '#open-' + $modal.attr('id');
    }

  });

  // open modal from hash
  var hash = window.location.hash;
  if (hash.indexOf('#open-') >= 0) {
    var itemId = hash.replace('open-', ''),
      $modal = $(itemId);

    if ($modal.length && $(window).width() < 767) {
      $modal.addClass('md-show');
      if ($modal.hasClass('md-cover')) {
        esign.cache.$html.addClass('noscroll');
      }
    }
  }

  // close modal
  $('.md-close-trigger, .md-overlay').click(function (e) {
    $('.md-show').removeClass('md-show');
    esign.cache.$html.removeClass('noscroll');

    location.hash = '';
  });

  // handle keyboard events
  $(document).on('keydown', function (e) {
    var tag = e.target.tagName.toLowerCase();

    if (tag != 'input' && tag != 'textarea') {
      // hide modal on escape
      if (e.which === 27 && $('.md-show').length) {
        $('.md-show').removeClass('md-show');
        esign.cache.$html.removeClass('noscroll');

        location.hash = '';
      }

      if (e.which === 77) {
        $('.main-modal-trigger').trigger('click');
      }

    }
  });

};


esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function () {
  $('.form-ajax').submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};


// Log ga calls in development
esign.gaDevelopment = function () {
  if (typeof ga === typeof undefined) {
    window.ga = function () {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

// Initialize on docready
$(esign.init);
